<template>
  <About :doctor-list="doctors" :licences="licences" />
</template>

<script>
import About from "@/components/AboutComponent";
import api from "@/api";
export default {
  name: "AboutView",
  components: {
    About,
  },
  data() {
    return {
      doctors: [],
      licences: [],
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        this.doctors = await api.doctors.getList()
        this.licences = await api.clinic.getImages()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>


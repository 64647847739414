<template>
  <footer class=footer>
    <div class="container footer-wrap">
      <div class="footer-left-wrap">
        © {{(new Date()).getFullYear()}}, ООО «Доверие» стоматология на Юровской
      </div>
      <div class="footer-right-wrap">
        <div class="footer-telephone">
          <h3 class="ui-title-3 telephone">
            <div>
              <a href="tel:+79917914044">+7 (991) 791-40-44</a>
            </div>
          </h3>
        </div>
        <div class="ui-title-3 footer-email">
          <h3 class="email">
            <a class="email-link" href="mailto:doveriestom@yandex.ru">doveriestom@yandex.ru</a>
          </h3>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'v-footer'
}
</script>

<style lang="stylus" scoped>
  .footer-wrap
    display flex
    justify-content space-between
    border-top 1px solid #ccc
  .footer-right-wrap
    display flex
    flex-direction column
  .footer-left-wrap
    display flex
    align-items flex-end
    margin-bottom 0.9rem
    text-align center
  .footer-telephone,
  .footer-email
    display flex
    justify-content flex-end
  .email-link
    text-text-decoration none
  .header-logo
    z-index 443
  .telephone
    font-weight 600
  @media screen and (max-width 480px)
    .footer-wrap
      flex-direction column-reverse
    .footer-left-wrap,
    .footer-telephone,
    .footer-email
      justify-content center
</style>

import APIPrototype from "../APIPrototype";
import { getLink } from "@/utils/helpers";

class APIDoctors extends APIPrototype {
  // eslint-disable-next-line
  async getList() {
    try {
      const response = await this.axios.get(this.getPath("/"));
      const { doctors } = response.data;

      doctors.map((doc) => {
        doc.link = getLink(doc.image);
      });

      return doctors;
    } catch (e) {
      console.log(e)
      return e;
    }
  }

  async getInfo(id) {
     return this.axios.get(this.getPath(`/${id}`));
  }
}

export default APIDoctors;

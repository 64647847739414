<template>
  <div class="content-wrapper">
    <section class="main-info">
      <div class="container">
        <h1 class="ui-title-1 center">
          Информация
        </h1>
      </div>
    </section>
    <section class="manage-organization-info">
      <div class="container">
        <h2 class="ui-title-3 center">
          О нас
        </h2>
        <div>
          Наша поликлиника организована в августе 2010 года.<br>
          Полное наименование юрлица: Общество с ограниченной ответственностью "Доверие" Стоматология на Юровской<br>
          Сокращенное: ООО "Доверие" Стоматология на Юровской.<br>
          ИНН: 4345284495, КПП 434501001<br>
          Свидетельство о государственной регистрации № 1104345015246, дата внесения сведений о юр.лице в Единый государственный реестр юридических лиц 2 августа 2010 года, выдано Инспекцией Федеральной налоговой службы Российской Федерации по городу Кирову.<br>
        </div>
      </div>
      <div class="container">
        <h2 class="ui-title-3 docs-title center">
          Сведения о госрегистрации и сертификаты
        </h2>
        <div class="row grid-center">
          <div class="col-xs-12 col-sm-6 col-md-4 docs-wrap" v-for="doc in docsList" :key="doc.id">
            <div class="ui-card" @click="clickDoc(doc)">
              <img :src="`/static/img/${doc.image}`" alt="Document scan" class="docs-scan">
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      docsList: [
        {
          id: 1,
          image: 'dov-lic.jpg'
        },
        {
          id: 2,
          image: 'dov-lic3.jpg'
        },
        {
          id: 3,
          image: 'dov-lic2.jpg'
        },
        {
          id: 4,
          image: 'certificate.jpg'
        },
        {
          id: 5,
          image: 'inn.jpg'
        }
      ],
      selectImage: {
        src: '',
        title: ''
      }
    }
  },
  methods: {
    clickDoc (doc) {
      this.selectImage = {
        src: doc.image,
        title: 'Document'
      }

    }
  }
}
</script>

<style lang="stylus" scoped>
  .docs-wrap
    margin-bottom 30px
  .ui-card:hover
    cursor pointer
  .ui-title-1
    text-shadow 5px 2px 5px rgba(150, 150, 150, 0.77)
</style>

<template>
  <div class="content-wrapper">
    <section class="main-info">
      <div class="container">
        <h1 class="ui-title-1 center">
          Информация о контролирующих организациях
        </h1>
      </div>
    </section>
    <div class="organizations-table">
      <table class="ui-table ui-table--hover organizations">
        <thead>
          <tr class="table-column-name">
            <th><span>Организация</span></th>
            <th><span>Адрес</span></th>
            <th><span>Контакты для связи</span></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in organizations" :key="item.id">
            <td><span class="ui-text-regular">{{ item.title }}</span></td>
            <td><span class="ui-text-regular">{{ item.address }}</span></td>
            <td><span class="ui-text-regular">{{ item.contacts }}</span></td>
          </tr>
        </tbody>
      </table>
      <div class="container-mobile" v-for="item in organizations" :key="item.id">
        <div class="row grid-center">
          <div class="col-xs-12 col-sm-6 col-md-8">
            <div class="ui-card ui-card--shadow-always bg-primary">
              <div class="organization-info">
                <div class="grid-example-block block-info">
                  <p class="ui-title-2 center">
                    {{ item.title }}
                  </p>
                  <p class="ui-title-3 center">
                    {{ item.address }}
                  </p>
                  <p class="ui-title-3 center">
                    {{ item.contacts }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import organizations_list from '@/assets/docs/control_organizations.json'
export default {
  data () {
    return {
      organizations_list: organizations_list
    }
  },
  props: {
    organizations: {
      type: Array,
    }
  },
  methods: {

  }
}
</script>

<style lang="stylus" scoped>
  .content-wrapper
    max-width 1280px
    margin 0 auto
    height auto
    min-height auto
  .organizations
    margin-bottom 50px
  .container-mobile
    display none
  .grid-center
    margin auto
    margin-bottom 30px
  .ui-title-1
    text-shadow 5px 2px 5px rgba(150, 150, 150, 0.77)
  @media screen and (max-width 500px)
    .organizations
      display none
    .container-mobile
      display flex
</style>

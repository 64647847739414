<template>
  <div class="home-wrap">
    <section>

    <div class="container-fluid">
      <div class="second">
        <div class="content-wrapper">
          <div class="row grid-start w-inherit" style="margin: 0 !important;">
            <div class="col-xs-12 col-sm-6 col-md-7 main-title">
              <div class="container">
                <Vue3Lottie :animation-data="lottie" />
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-5 main-info">
              <div class="container home-info-wrap">
                <span class="home-info ui-title-2">
                  <span style="color: #377EE6;">Доверьтесь</span> нам - сделаем вашу улыбку неотразимой
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <section class="main-con">

    </section>
    <section class="main-content-info">
      <div class="container">
        <h1 class="ui-title-1 title-information center">
          Коротко о нашей клинике
        </h1>
        <div class="fact-wrap">
          <div class="row grid-center fact-list">
            <div class="col-md-4 col-sm-6 col-xs-12 fact-card-wrap" v-for="fact in factsList" :key="fact.title">
              <div class="ui-card ui-card--shadow-always fact-card">
                <div class="card-pic_wrap">
                  <i class="material-icons card-pic" :id="fact.icon">{{ fact.icon }}</i>
                </div>
                <h2 class="ui-title-3 title-card">
                  {{ fact.title }}
                </h2>
                <span>{{ fact.subtitle }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="carousel-interier" v-if="images.length > 0">
      <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="(image, index) in images" :key="index">
          <div class="carousel__item">
            <div class="interier-wrapper">
              <img
                  :src="image.link"
                  alt="Document scan"
                  class="docs-scan"
              >
            </div>
          </div>
        </Slide>
      </Carousel>
    </section>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import lottieData from '@/assets/lottie/131819-dental-clinic.json'
import { Vue3Lottie } from "vue3-lottie";
import "vue3-lottie/dist/style.css";

export default {
  data () {
    return {
      isBodyHidden: false,
      isPopupVisible: false,
      lottie: lottieData,
      factsList: [
        {
          title: '> 9 лет работы',
          subtitle: 'За время работы клиники, через заботливые руки наших специалистов прошло огромное количество человек.',
          icon: 'hourglass_empty'
        },
        {
          title: 'Профессионализм',
          subtitle: 'Наши специалисты являются мастерами своего дела. У них за плечами годы практики и огромнейший опыт в лечении.',
          icon: 'school'
        },
        {
          title: 'Качество лечения',
          subtitle: 'На первом месте в нашей клинике стоит качественное лечение с использованием лучших материалов.',
          icon: 'star_border'
        },
        {
          title: 'Доступные цены',
          subtitle: 'Ваша улыбка - главная награда для нас. Мы стараемся оптимизировать ваши расходы доступными ценами.',
          icon: 'monetization_on'
        },
        {
          title: 'Бережное лечение',
          subtitle: 'Вам больше не нужно бояться стоматологов. Мы позаботимся о том, чтобы вы чувствовали себя как дома.',
          icon: 'loyalty'
        },
        {
          title: 'Новые технологии',
          subtitle: 'Мы стараемся идти в ногу со временем и применять новейшее оборудование и технологии в лечении.',
          icon: 'desktop_mac'
        }
      ],
      // carousel settings
      settings: {
        itemsToShow: 5,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        280: {
          itemsToShow: 1,
          snapAlign: 'center',
          wrapAround: true,
          autoplay: 5000,
        },
        // 1024 and up
        480: {
          itemsToShow: 1,
          snapAlign: 'center',
          wrapAround: true,
          autoplay: 5000,
        },
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
          wrapAround: true,
          autoplay: 5000,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
          wrapAround: true,
          autoplay: 5000,
        },
        1600: {
          itemsToShow: 4,
          snapAlign: 'start',
          wrapAround: true,
          autoplay: 5000,
        },
        2100: {
          itemsToShow: 5,
          snapAlign: 'start',
          wrapAround: true,
          autoplay: 5000,
        }
      },
    }
  },
  props: {
    images: {
      type: Array
    }
  },
  components: {
    //vPopup,
    Carousel,
    Slide,
    //Navigation,
    Vue3Lottie
  },
  methods: {
    showPopup () {
      let popup = document.getElementById('popup')
      popup.style.display = 'table'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .w-inherit
    width inherit
  .content-wrapper
    max-width 1280px
    margin 0 auto
    height auto
    min-height auto
  .main-title,
  .main-info
    display flex
    align-items center
    color black
  .ui-card
    width 100%
    display grid
  .home-info-wrap
    text-align left
    padding-left 0
  .home-info
    margin-bottom 30px
    font-weight 600
  .button
    margin 0 auto
  .ui-title-1
    vertical-align inherit
    font-weight 800
    letter-spacing .05em
    margin 0 auto
    color black
  .first
    margin-bottom 0
  .third
    margin-top 0
  .title-information
    color black
    margin-bottom 30px
    font-size 40px
    font-weight 600
  .main-visits
    margin-bottom 60px
  .fact-wrap
    margin-bottom 60px
  .fact-card
    display inline-grid
    align-items center
    text-align justify
    height 100%
    border-radius 3em
  .fact-card:hover
    cursor pointer
    transform scale(1.05)
    transition .3s
    -webkit-box-shadow 0 0 19px 2px rgba(168,167,167,1)
    -moz-box-shadow 0 0 19px 2px rgba(168,167,167,1)
    box-shadow 0 0 19px 2px rgba(168,167,167,1)
    .card-pic
      transform rotate(360deg)
      transition .7s
  .card-pic_wrap
    height 60px
  .card-pic
    font-size 50px
  .first
    padding-top 0
  .fact-card-wrap
    margin-bottom 30px
  .interier-wrapper
    display flex
    margin auto
    padding 5px
    img
      margin auto
      width 100%
      border-radius 1.3em
  .carousel-interier
    margin-bottom 40px
@media screen and (max-width 320px)
  section
    padding 0
  .fact-list
    margin auto
@media screen and (max-width 480px)
  .ui-title-1
    font-size 30px
@media screen and (max-width 980px)
  .main-info
    margin-top 30px
    margin-bottom 30px
@media screen and (max-width 1280px)
  section
    padding 0
    margin 0
@media screen and (min-width 1280px)
  section
    padding 0
</style>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import ServicesView from '@/views/ServicesView'
import AboutView from '@/views/AboutView'
import InfoView from '@/views/InfoView'
import VisitView from '@/views/VisitView'
import LinksView from '@/views/LinksView'
import NotFoundView from '@/views/NotFoundView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Стоматология "Доверие"',
      metaTags: [

      ]
    }
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView,
    meta: {
      title: 'Цены - Стоматология "Доверие"',
      metaTags: [

      ]
    }
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: {
      title: 'О нас - Стоматология "Доверие"',
      metaTags: [

      ]
    }
  },
  {
    path: '/info',
    name: 'info',
    component: InfoView,
    meta: {
      title: 'Стоматология "Доверие"',
      metaTags: [

      ]
    }
  },
  {
    path: '/visit',
    name: 'visit',
    component: VisitView,
    meta: {
      title: 'Контакты - Стоматология "Доверие"',
      metaTags: [

      ]
    }
  },
  {
    path: '/robots.txt'
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
  // Set the document title and meta tags
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.meta.metaTags) {
    const metaTags = to.meta.metaTags;
    metaTags.forEach((metaTag) => {
      const tag = document.createElement('meta');
      Object.keys(metaTag).forEach((key) => {
        tag.setAttribute(key, metaTag[key]);
      });
      document.head.appendChild(tag);
    });
  }

  if (to.meta.scriptTags) {
    const scriptTags = to.meta.scriptTags;
    scriptTags.forEach((scriptTag) => {
      const tag = document.createElement('script');
      Object.keys(scriptTag).forEach((key) => {
        tag.setAttribute(key, scriptTag[key]);
      });
      document.head.appendChild(tag);
    });
  }

  next();
});

export default router

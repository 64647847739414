import axios from 'axios'
import APIDoctors from '@/api/doctors'
import APIClinic from '@/api/clinic'
import APIPrice from '@/api/price-list'
import APIOrganization from '@/api/сontrol-organization'

const axiosClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

const apiDoctors = new APIDoctors(axiosClient, 'doctors')
const apiClinic = new APIClinic(axiosClient, 'clinic')
const apiPrice = new APIPrice(axiosClient, 'price-list')
const apiOrganization = new APIOrganization(axiosClient, 'control-organization')

export default {
  clinic: apiClinic,
  doctors: apiDoctors,
  price: apiPrice,
  control: apiOrganization
}

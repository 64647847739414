<template>
  <header>
    <div class="navbar">
      <div class="container">
        <div class="navbar-content">
          <router-link class="header-logo" to="/">
            <img src="@/assets/logo.svg" alt="Doverie" class="logo-pic">
          </router-link>
          <div class="button-burger" @click="burgerClick" :class="{ active: menuShow }">
            <span class="line line-1"></span>
            <span class="line line-2"></span>
            <span class="line line-3"></span>
          </div>
          <div class="navbar-list__wrapper" :class="{ active: menuShow }">
            <ul class="navbar-list">
              <li class="navbar-item" v-for="link in linkMenu" :key="link.title" @click="linkClick">
                <router-link class="navbar-link" :to="link.url">
                  {{ link.title }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'v-menu',
  data () {
    return {
      menuShow: false,
      linkMenu: [
        { title: 'Главная', url: '/' },
        { title: 'Услуги', url: '/services' },
        { title: 'О нас', url: '/about' },
        { title: 'Контакты', url: '/visit' }
      ]
    }
  },
  methods: {
    burgerClick () {
      this.menuShow = !this.menuShow
      if (this.menuShow) {
        let page = document.getElementsByTagName('body')
        page[0].style = 'overflow:  hidden;'
      } else {
        let page = document.getElementsByTagName('body')
        page[0].style = 'overflow:  auto;'
      }
    },
    linkClick () {
      this.menuShow = false
      let page = document.getElementsByTagName('body')
      page[0].style = 'overflow:  auto;'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .logo-pic
    width  50px
  .navbar-link
    padding 0.5rem 0.75rem
  .navbar-link:last-child,
  .navbar-item:last-child
    padding-right 0.75rem
  .navbar-item:hover
    transform scale(1.1)
    transition .3s
  @media only screen and (max-width 768px)
    .navbar-list
      height calc(100% - 80px)
      justify-content center
    .navbar-link
      padding 0.5rem 0
      font-size 20px
    .navbar-link:last-child,
    .navbar-item:last-child
      padding-right 0
</style>

<template>
  <div class="content-wrapper">
    <v-photo :image="selectImage" />
    <section class="main-info">
      <div class="container">
        <h1 class="ui-title-1 center">
          Информация
        </h1>
      </div>
    </section>
    <section class="manage-organization-info">
      <div class="container">
        <h2 class="ui-title-3 center">
          О нас
        </h2>
        <div class="row about_info">
          <div class="col-xs-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 about_info-image_wrapper">
            <img src="@/assets/images/main.svg" alt="">
          </div>
          <div class="col-xs-12 col-sm-7 col-md-6 col-lg-6 col-xl-6">
            <p class="py-2">
              Наша стоматологическая клиника была основана в 2010 году с целью предоставления качественных стоматологических услуг в комфортной и дружелюбной обстановке.
              Мы стремимся обеспечить максимальный комфорт и удобство для наших пациентов, предлагая широкий спектр стоматологических услуг.
              В нашей команде работают профессионалы, которые обладают высокой квалификацией и огромным опытом работы в стоматологии.
            </p>
            <p class="py-2">
              Наша миссия заключается в том, чтобы помочь нашим пациентам сохранить здоровье и красоту своих зубов.
              Клиника оснащена современным оборудованием, что позволяет нам проводить лечение и процедуры на высоком уровне.
              Мы ценим доверие, которое наши пациенты оказывают нам, и готовы делать все возможное, чтобы заслужить это доверие.
            </p>
            <!--<div>
              Полное наименование юрлица: Общество с ограниченной ответственностью "Доверие" Стоматология на Юровской<br>
              Сокращенное: ООО "Доверие" Стоматология на Юровской.<br>
              ИНН: 4345284495, КПП 434501001<br>
              Свидетельство о государственной регистрации № 1104345015246, дата внесения сведений о юр.лице в Единый государственный реестр юридических лиц 2 августа 2010 года, выдано Инспекцией Федеральной налоговой службы Российской Федерации по городу Кирову.<br>
            </div>-->
          </div>
        </div>
      </div>
      <div class="container" v-if="licences.length > 0">
        <h2 class="ui-title-3 docs-title center">
          Сведения о госрегистрации и сертификаты
        </h2>
        <div>

          <carousel
              :settings="settings"
              :breakpoints="breakpoints"
          >
            <slide v-for="(doc, index) in licences" v-bind:key="index">
              <div
                class="ui-card"
                @click="clickDoc(doc)"
              >
                <img :src="doc.link" alt="Document scan" class="docs-scan">
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </section>
    <section class="about-content">
      <div class="container">
        <h1 class="ui-title-1 center">
          Наши врачи
        </h1>
      </div>
      <div class="container" v-for="doctor in doctorList" :key="doctor.id">
        <div class="row grid-center doctor-card">
          <div class="col-sm-12">
            <div class="ui-card ui-card--shadow-always bg-primary">
              <div class="doctor-info">
                <div class="grid-example-block block-info row">
                  <div class="col-sm-6">
                    <div class="thumb-block">
                      <img :src="doctor.link" alt="Image" class="doctor-pic" style="border-radius: 20%">
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <p class="ui-title-3 center">
                      {{ fullName(doctor) }}
                    </p>
                    <p class="ui-title-4 center">
                      {{ doctor.position }}
                    </p>
                    <span v-html="doctor.description"></span>
                  </div>
                  <div class="col-sm-12">
                    <Carousel :settings="settings"
                              :breakpoints="breakpoints">
                      <Slide v-for="cert in doctor.media_files" :key="cert">
                        <div class="ui-card" @click="clickSert(cert)">
                          <img :src="getPhotoUrl(cert)" alt="Document scan" class="docs-scan" style="max-height: 300px">
                        </div>
                      </Slide>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <vue-easy-lightbox
      :visible="showPhoto"
      :imgs="[selectImage.src]"
      @hide="onHide"
  ></vue-easy-lightbox>
</template>

<script>
import sertificates from '@/assets/docs/sertificates.json'
//import vPhoto from '@/components/photoPopup/v-photo'
import { Carousel, Slide } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import VueEasyLightbox from 'vue-easy-lightbox'
import {getLink} from "@/utils/helpers";
export default {
  data () {
    return {
      docsList: sertificates,
      selectImage: {
        src: '',
        title: ''
      },
      showPhoto: false,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        280: {
          itemsToShow: 1,
          snapAlign: 'center',
          wrapAround: true,
          autoplay: 5000,
        },
        // 1024 and up
        480: {
          itemsToShow: 1,
          snapAlign: 'center',
          wrapAround: true,
          autoplay: 5000,
        },
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
          wrapAround: true,
          autoplay: 5000,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
          wrapAround: true,
          autoplay: 5000,
        },
      },
    }
  },
  props: {
    doctorList: {
      type: Array,
    },
    licences: {
      type: Array,
    }
  },
  components: {
    Carousel,
    Slide,
    VueEasyLightbox,
  },
  methods: {
    gridReverse (id) {
      return (id % 2 !== 0) ? '' : 'grid-reverse'
    },
    fullName(doctor) {
      return `${doctor.surname} ${doctor.name} ${doctor.patronymic_name}`
    },
    getPhotoUrl(path) {
      return getLink(path)
    },
    clickDoc (doc) {
      this.selectImage = {
        src: doc.link,
        title: 'Document'
      }
      this.onShow()
    },
    onHide() {
      this.showPhoto = false
    },
    onShow() {
      this.showPhoto = true
    },
    clickSert(sert) {
      this.selectImage = {
        src: getLink(sert),
        title: 'Document'
      }
      this.onShow()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .py-2
    padding 8px 0
  .about_info
    margin 0
    align-items center
    .about_info-image_wrapper
      display flex
      align-items center
      justify-content center

  .main-info, .about-title
    padding-bottom 0
  .about-title
    opacity .9
  .grid-example-block
    align-items center
  .doctor-info
    display flex
    align-items center
    height 100%
  .ui-title-1
    text-shadow 5px 2px 5px rgba(150, 150, 150, 0.77)
  .ui-card
    background-color transparent
    border 0
  .row
    margin-bottom 30px
  .grid-example-block
    width 100%
  .doctor-pic
    border-radius 7px
    margin 0 auto
    width 70%
    max-width 350px
  .thumb-block
    display flex
    align-items center
    height 100%
  .block-info
    text-align justify
  .docs-wrap
    margin-bottom 30px
  .ui-card:hover
    cursor pointer
@media only screen and (max-width 980px)
  .thumb-block
    margin-bottom 60px
  .doctor-card
    margin auto

</style>

<template>
    <main class="grid content place-items-center py-24 px-6 sm:py-32 lg:px-8">
        <div class="text-center">
            <p class="text-number">404</p>
            <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                Ой, страница не найдена
            </h1>
            <p class="info">
                Кажется вы забрели не туда.
            </p>
        </div>
    </main>
</template>

<script>
export default {
    name: "NotFoundView",
};
</script>

<style lang="stylus" scoped>
.content
    display flex
    align-items center
    justify-content center
    text-align center
.text-number
    font-size 60px
    font-weight 600
    color rgb(55, 126, 230)
h1
    font-size 40px
.info
    font-size 28px
@media screen and (max-width 480px)
    .text-number
        font-size 45px
        font-weight 600
        color rgb(55, 126, 230)
    h1
        font-size 30px
    .info
        font-size 22px
</style>
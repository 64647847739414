<template>
  <div class="content-wrapper">
    <section class="main-title">
      <div class="container">
        <h1 class="ui-title-1 center">
          Контакты
        </h1>
      </div>
    </section>
    <div class="fact-wrap">
      <div class="row grid-center">
        <div class="col-xs-12 col-sm-6 col-md-4 py-2">
          <div class="ui-card ui-card--shadow-always fact-card first-card">
            <div class="card-pic_wra">
              <div class="card-title">
                Адрес
                <i class="material-icons" id="location_on">location_on</i>
              </div>
            </div>
            <div class="title-card">
              610037, г. Киров, ул. Риммы Юровской, д.2.
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 py-2">
          <div class="ui-card ui-card--shadow-always fact-card second-card">
            <div class="card-pic_wra">
              <div class="card-title">
                Режим работы
                <i class="material-icons" id="access_time">access_time</i>
              </div>
            </div>
            <div>
              <div class="work-day">
                Понедельник-Пятница
                <div class="work-hour">
                  08-19
                </div>
              </div>
              <div class="work-day">
                Суббота
                <div class="work-hour">
                  08-14
                </div>
              </div>
              <div class="work-day">
                Воскесенье
                <div class="work-hour">
                  выходной
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 py-2">
          <div class="ui-card ui-card--shadow-always fact-card third-card">
            <div class="card-pic_wra">
              <div class="card-title">
                Контакты для связи
                <i class="material-icons" id="phone">phone</i>
              </div>
            </div>
            <div class="title-card">
              <a href="tel:+79917914044" class="phone">+7 (991) 791-40-44</a>
            </div>
            <div class="title-card">
              <a class="email" href="mailto: doveriestom@yandex.ru">doveriestom@yandex.ru</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="main-info">
      <div class="map-container">
        <iframe
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Afc0d6084b1eaaba43a02ac93ae7aff70748ecfbefbaf60bf163a40711dc2fc40&amp;source=constructor"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
      </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  head: {
    script: [
      {
        src: "https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3Afc0d6084b1eaaba43a02ac93ae7aff70748ecfbefbaf60bf163a40711dc2fc40&amp;width=100%25&amp;height=100%&amp;lang=ru_RU&amp;scroll=true"
      }
    ]
  }
}
</script>

<style lang="stylus" scoped>
  .py-2
    padding-top 20px
    padding-bottom 20px
  .map-container
    width: 100%
    height 60vh
    -webkit-box-flex: 0
    -ms-flex: 0 0 auto
    flex: 0 0 auto
    position: relative
    margin: 0 auto
  .ui-title-1
    text-shadow 5px 2px 5px rgba(150, 150, 150, 0.77)
  .main-info
    height inherit
  .material-icons
    font-size 40px
  .info-span
    font-size 26px
  .fact-wrap
    margin auto
    margin-bottom 60px
    max-width 1280px
  .fact-card
    display inline-grid
    align-items center
    text-align justify
    height 100%
    border-radius 3em
    width 100%
  .card-pic_wrap
    height 60px
  .card-pic
    width 50px
  .work-day
    display flex
    justify-content space-between
  .card-title
    display flex
    flex-direction column-reverse
    align-items center
    justify-content center
    margin 10px auto
    font-size 24px
  .title-card
    display flex
    align-items center
    justify-content center
  .row
    margin-left 0
    margin-right 0
</style>

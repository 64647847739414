import APIPrototype from '../APIPrototype'

class APIOrganization extends APIPrototype {
  // eslint-disable-next-line
  async getData () {
    // eslint-disable-next-line no-return-await
    return await this.axios.get(this.getPath('/'))
  }
}

export default APIOrganization

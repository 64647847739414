<template>
  <div class="content-wrapper">
    <section class="services-title">
      <h1 class="ui-title-1 center">
        Услуги
      </h1>
    </section>
    <section class="services-content">
      <div class="container">
        <div v-for="category in priceList" v-bind:key="category.id">
          <div class="surgery-table show-table">
            <h2 class="surgery-tablename">
              {{category.name}}
            </h2>
          </div>
          <table class="ui-table ui-table--hover surgery active_table" v-if="category.prices.length > 0">
            <thead>
              <tr class="table-column-name">
                <th><span>№</span></th>
                <th><span>Услуга</span></th>
                <th><span>Цена</span></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in category.prices" :key="item.id">
                <td><span class="ui-text-regular">{{ item.id }}</span></td>
                <td><span class="ui-text-regular">{{ item.title }}</span></td>
                <td class="price-column">
                  <span class="ui-text-regular">
                    {{ item.price }}
                  </span>
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ruble-sign" class="svg-inline--fa fa-ruble-sign fa-w-12 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="0">
                    <path fill="currentColor" d="M239.36 320C324.48 320 384 260.542 384 175.071S324.48 32 239.36 32H76c-6.627 0-12 5.373-12 12v206.632H12c-6.627 0-12 5.373-12 12V308c0 6.627 5.373 12 12 12h52v32H12c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h52v52c0 6.627 5.373 12 12 12h58.56c6.627 0 12-5.373 12-12v-52H308c6.627 0 12-5.373 12-12v-40c0-6.627-5.373-12-12-12H146.56v-32h92.8zm-92.8-219.252h78.72c46.72 0 74.88 29.11 74.88 74.323 0 45.832-28.16 75.561-76.16 75.561h-77.44V100.748z"></path>
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else class="no-data">
            Нет данных
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import mySurgeryList from '@/assets/docs/surgeryList.json'
import myOrthopedicList from '@/assets/docs/orthopedicList.json'
import myTherapyList from '@/assets/docs/therapyList.json'

export default {
  props: {
    priceList: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      surgeryShow: false,
      orthopedicShow: false,
      therapyShow: false,
      surgeryList: mySurgeryList,
      orthopedicList: myOrthopedicList,
      therapyList: myTherapyList,
      services: [
        {
          id: 1,
          title: 'Профилактика стоматологических заболеваний'
        },
        {
          id: 2,
          title: 'Отбеливание зубов'
        },
        {
          id: 3,
          title: 'Лечение заболеваний пародонта'
        },
        {
          id: 4,
          title: 'Лечение кариеса и некариозных поражений твердых тканей зуба'
        },
        {
          id: 5,
          title: 'Лечение осложненных форм кариеса с помощью современных методик'
        },
        {
          id: 6,
          title: 'Хирургическая стоматология'
        },
        {
          id: 7,
          title: 'Установка имплантантов'
        },
        {
          id: 8,
          title: 'Все виды протезирования по новейшим мировым технологиям, включая протезирование на имплантантах'
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
  .no-data
    text-align center
  .surgery-table,
  .orthopedics-table,
  .therapy-table
    width 100%
    border-radius 7px
    display flex
    align-items center
    justify-content center
    height 70px
    margin-bottom 40px
  .surgery-tablename,
  .orthopedics-tablename,
  .therapy-tablename
    font-size 30px
  .surgery,
  .orthopedic,
  .therapy
    display none
  .active_table
    display inline-table
  .ui-table
    margin-bottom 40px
    background-color transparent
    .price-column
      width 130px
  .ui-text-regular
    color #222222
  .subtable-name
    color #222222
  .fa-ruble-sign
    width 0.6rem
    margin-top -4px
  .ui-title-1
    text-shadow 5px 2px 5px rgba(150, 150, 150, 0.77)
@media screen and (max-width 1280px)
  .ui-table
    td
      padding 14px 0 14px 14px
    .price-column
      width 80px
@media screen and (max-width 768px)
  .ui-table
  td
    padding 14px 0 14px 14px
  .price-column
    width auto
@media screen and (max-width 350px)
  .table-column-name:last-child
    padding 0
  tr:last-child
    padding 0
</style>

<template>
  <Home :images="images"/>
</template>

<script>
// @ is an alias to /src
import api from '@/api'
import Home from '@/components/HomeComponent.vue'

export default {
  name: 'HomeView',
  components: {
    Home
  },
  data() {
    return {
      images: []
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        this.images = await api.clinic.getInterior()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

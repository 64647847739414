<template>
  <div class="wrapper">
    <v-menu/>
    <router-view class="content" />
    <v-footer/>
  </div>
</template>

<script>
import VMenu from '@/components/menu/v-menu'
import VFooter from '@/components/footer/v-footer'

export default {
  components: {
    VFooter,
    VMenu
  }
}
</script>

<style lang="stylus">
@import '@/assets/stylus/main.styl'
@import '@/assets/css/fontstyle.styl'
.wrapper
  max-width 100%
.content
  min-height: calc(100vh - 190px) !important

body
  background-color: #ffffff
  min-height: 100vh

</style>

import APIPrototype from "../APIPrototype";
import { getLink } from "@/utils/helpers";

class APIClinic extends APIPrototype {
  // eslint-disable-next-line
  async getImages() {
    const response = await this.axios.get(this.getPath("/"));
    const { images } = response.data;

    images.map((image) => {
      image.link = getLink(image.image);
    });

    return images;
  }

  // eslint-disable-next-line
  async getInterior() {
    const response = await this.axios.get(this.getPath("/interior"));
    const { images } = response.data;

    images.map((image) => {
      image.link = getLink(image.image);
    });

    return images;
  }
}

export default APIClinic;

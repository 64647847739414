<template>
  <Services :price-list="priceList"/>
</template>

<script>
import Services from "@/components/ServicesComponent";
import api from "@/api";

export default {
  name: "ServicesView",
  components: {
    Services
  },
  data() {
    return {
      priceList: []
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        const response = await api.price.getData()
        this.priceList = response.data.data;
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

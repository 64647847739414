class APIPrototype {
  constructor (axios, apiDir) {
    this.axios = axios
    this.apiDir = apiDir
  }

  getPath (url) {
    return this.apiDir + url
  }
}

export default APIPrototype

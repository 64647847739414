<template>
  <Visit/>
  <Links :organizations="organizations" />
</template>

<script>
import Visit from "@/components/VisitComponent";
import Links from "@/components/LinksComponent";
import api from "@/api";

export default {
  name: "VisitView",
  components: {
    Links,
    Visit
  },
  data() {
    return {
      organizations: []
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      try {
        const response = await api.control.getData()
        const {organizations} = response.data
        this.organizations = organizations
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<template>
  <Info/>
</template>

<script>
import Info from "@/components/InfoComponent";

export default {
  name: "InfoView",
  components: {
    Info,
  }
}
</script>

